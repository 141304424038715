<template>
    <div class="orderCount">
        <!-- 订单统计 -->
        <h3>业绩分析</h3>
        <div class="searchForm">
            <a-range-picker 
                :placeholder="['开始时间' , '结束时间']" v-model="timeData"
                valueFormat="YYYY-MM-DD"
            >
                <a-icon slot="suffixIcon" type="calendar" />
            </a-range-picker>
            <!-- <div class="ul"> -->
                <a-button  v-for="(item) in time" :key="item.key" @click="clickTime(item.key)"  :class="[activeIndex === item.key ? 'searchBtn' : 'cancleBtn']">{{item.value}}</a-button>
                <!-- <li v-for="(item) in time" :key="item.key" @click="clickTime(item.key)"  :class="[activeIndex === item.key ? 'active' : '', 'type']">{{item.value}}</li> -->
            <!-- </div> -->
            <a-button class="searchBtn btn" @click="searchHandle">查询</a-button>
        </div>
        <div class="firstModal">
            <ul>
                <li>
                    <div class="img"><my-icon slot="prefix" type="icon-qian" style="color: rgba(0,0,0,.25);width:2vw;height:2vw;"></my-icon></div>
                    <div><div class="font">销售额(元)</div><div class="count">{{resource?resource.payedPrice:0.00}}</div></div>
                </li>
                 <li>
                    <div class="img"><my-icon slot="prefix" type="icon-dingdan1" style="color: rgba(0,0,0,.25);width:2vw;height:2vw;"></my-icon></div>
                    <div><div class="font">订单数(笔)</div><div class="count">{{resource?resource.orderNum:0}}</div></div>
                </li>
                 <li>
                    <div class="img"><my-icon slot="prefix" type="icon-shangpin" style="color: rgba(0,0,0,.25);width:2vw;height:2vw;"></my-icon></div>
                    <div><div class="font">商品数(件)</div><div class="count">{{resource?(resource.goodsNum).toFixed(2):0}}</div></div>
                </li>
                 <li>
                    <div class="img"><my-icon slot="prefix" type="icon-kaitaishangpin" style="color: rgba(0,0,0,.25);width:2vw;height:2vw;"></my-icon></div>
                    <div><div class="font">开台数量</div><div class="count">{{resource?resource.foundingNum:0}}</div></div>
                </li>
                 <li>
                    <div class="img"><my-icon slot="prefix" type="icon-huiyuan" style="color: rgba(0,0,0,.25);width:2vw;height:2vw;"></my-icon></div>
                    <div><div class="font">会员消费数量</div><div class="count">{{resource?resource.userBalanceNum:0}}</div></div>
                </li>
                 <li>
                    <div class="img"><my-icon slot="prefix" type="icon-renshu" style="color: rgba(0,0,0,.25);width:2vw;height:2vw;"></my-icon></div>
                    <div><div class="font">接待客人</div><div class="count">{{resource?resource.peopleNum:0}}</div></div>
                </li>
                 <li>
                    <div class="img"><my-icon slot="prefix" type="icon-chongzhijine-xianxing" style="color: rgba(0,0,0,.25);width:2vw;height:2vw;"></my-icon></div>
                    <div><div class="font">充值金额(元)</div><div class="count">{{resource?resource.rechargePrice:0.00}}</div></div>
                </li> 
            </ul>
        </div>
        <div class="secondModal">
            <div class="leftModal">
                <div class="circleprogress">
                    <div>
                        <div>客单价</div>
                        <div style="fontWeight:bold">{{resource?resource.unitPrice:0.00}}</div>
                    </div>
                </div>
                 <div class="circleprogress">
                    <div>
                        <div>人均消费</div>
                        <div style="fontWeight:bold">{{resource?resource.perCapitaPrice:0.00}}</div>
                    </div>
                </div>
                 <div class="circleprogress">
                    <div>
                        <div>忠实粉丝</div>
                        <div style="fontWeight:bold">{{resource?resource.fansNum:0}}</div>
                    </div>
                </div>
                 <div class="circleprogress">
                    <div>
                        <div>翻台率</div>
                        <div style="fontWeight:bold">{{resource?resource.returnTableNum:0}}</div>
                    </div>
                </div>
            </div>
            <div class="rightModal">
                <div id="myChart" :style="{width: '48vw', height: '300px',paddingBottom:'1vh'}"></div>
            </div>
        </div>
        <div class="thirdModal">
             <div id="main" :style="{width: '90vw', height: '279px'}"></div>
        </div>
        <div class="fourthModal">
            <div class="bartitle top">会员充值/消费</div>
            <div class="bartitle">
                <span class="left" style="fontWeight:bold">{{chong}}</span>
                <span class="right" style="fontWeight:bold">{{xiao}}</span>
            </div>
            <div class="bartitle">
                <span class="normal">会员充值金额</span>
                <span class="normal">会员消费金额</span>
            </div>
            <div id="bar" :style="{width: '100%', height: '305px',paddingBottom:'1vh'}"></div>
        </div>
        <div class="fifthModal">
            <div class="bartitle top">会员分析</div>
            <div class="bartitle">
                <span class="left" style="fontWeight:bold">{{huo}}</span>
                <span class="right" style="fontWeight:bold">{{fu}}</span>
            </div>
            <div class="bartitle">
                <span class="normal">会员活跃数</span>
                <span class="normal">复购数</span>
            </div>
             <div id="bars" :style="{width: '100%', height: '305px',paddingBottom:'3vh',background:'#fff'}"></div>
        </div>
    </div>
</template>
<script>
import { dataStatistics } from '@/request/reportForm'
import Vue from 'vue'
import iconFront from '@/assets/iconfont/iconfont.js'
import iconFrontDing from '@/assets/iconfont/iconfontone.js'
import iconFrontShang from '@/assets/iconfont/iconfonttwo.js'
import iconFrontTai from '@/assets/iconfont/iconfontthree.js'
import iconFrontHui from '@/assets/iconfont/iconfontfour.js'
import iconFrontRen from '@/assets/iconfont/iconfontfive.js'
import iconFrontChong from '@/assets/iconfont/iconfontsix.js'
import 'echarts/lib/component/dataZoom'
import { Divider, Icon } from 'ant-design-vue';
import 'echarts/lib/component/grid';
let echarts = require('echarts/lib/echarts');
    require('echarts/lib/chart/pie');
    require('echarts/lib/chart/bar');
    require('echarts/lib/chart/line');
    require('echarts/lib/component/title');      
    require('echarts/lib/component/tooltip');      
    require('echarts/lib/component/legend');      
    require("echarts/lib/component/legendScroll");
    require("echarts/lib/chart/bar");
 const myicon = Icon.createFromIconfontCN({
    scriptUrl: iconFront,
    scriptUrl: iconFrontDing,
    scriptUrl: iconFrontShang,
    scriptUrl: iconFrontTai,
    scriptUrl: iconFrontHui,
    scriptUrl: iconFrontRen,
    scriptUrl: iconFrontChong,
 })
Vue.component('my-icon', myicon)
export default {
    name:'orderCount',
    components:{ },
    data(){
        return{
            timeData:[],
            searchForm:{
                listRows:15,
                page:1,
                total:0
            },
            searchedForm:{
                listRows:15,
                page:1,
                total:0
            },
            time:[{key:1,value:'当天'},{key:2,value:'一周'},{key:3,value:'一个月'}],
            activeIndex:1,//激活索引
            resource:'',//全部数据
            huanData:[],//环形图数据
            xAxisData: [], // x轴数据折线图
            yAxisData1: [], // 数据1 订单数
            yAxisData2: [], // 数据2 进账金额
            yAxisData3: [], // 数据3 视频广告
            xBar:[],//会员充值x轴数据
            yBar1:[],//y轴会员充值
            yBar2:[],//y轴会员消费
            xBars:[],//会员分析x轴
            yBars1:[],//y轴会员活跃数
            yBars2:[],//y轴复购数
            chong:0.00,//充值金额
            xiao:0.00,//消费金额
            huo:0,//活跃数
            fu:0,//复购数
        }
    },
    watch:{
        activeIndex:{
            handler(){
                if(this.activeIndex !==""){
                    this.timeData=[]
                }
            },
            deep:true,
        },
        timeData:{
            handler(){
                if(this.timeData.length==2){
                    this.activeIndex=""
                }
            },
            deep:true,
        },

    },
    mounted(){
        this.searchHandle();   
    },
    updated(){
    },
    methods:{
        searchHandle(){
            const timeData=this.timeData.length>0?this.timeData:[];
            if(timeData.length==2){
               this.activeIndex="" 
            }
            dataStatistics({select_type:this.activeIndex,start_time:timeData[0],end_time:timeData[1]}).then(res => {
            if(res.data.status === 200){
                this.resource=res.data.data && res.data.data
                const orderGoodsCharts=res.data.data.orderGoodsCharts?res.data.data.orderGoodsCharts:[]; //饼图
                const orderLineCharts=res.data.data.orderLineCharts?res.data.data.orderLineCharts:[];//折线图 订单数
                const payedLineCharts=res.data.data.payedLineCharts?res.data.data.payedLineCharts:[];//折线图 进账金额
                const peopleLineCharts=res.data.data.peopleLineCharts?res.data.data.peopleLineCharts:[];//折线图 客流量
                const rechargeLineCharts=res.data.data.rechargeLineCharts?res.data.data.rechargeLineCharts:[];//柱状图会员充值
                const balanceLineCharts=res.data.data.balanceLineCharts?res.data.data.balanceLineCharts:[];//柱状图会员消费
                const activeUserLineCharts=res.data.data.activeUserLineCharts?res.data.data.activeUserLineCharts:[];//活跃会员数
                const purchaseLineCharts=res.data.data.purchaseLineCharts?res.data.data.purchaseLineCharts:[];//复购数
                let pie=[]
                orderGoodsCharts.forEach(item => {
                    const num =parseFloat((item.num).toFixed(2))
                    pie.push({name:item.goods_name,value:num})
                });
                this.huanData=pie
                let a=[];
                let b=[];
                orderLineCharts.forEach(item => {
                    a.push(item.dateTime);
                    b.push(item.num);
                });
                this.xAxisData=a;
                this.yAxisData1=b;
                let c=[];
                payedLineCharts.forEach(item => {
                    c.push(item.num);
                });
                this.yAxisData2=c;
                let d=[];
                peopleLineCharts.forEach(item => {
                   d.push(item.num);
                });
                this.yAxisData3=d;
                let e=[];
                let f=[];
                rechargeLineCharts.forEach(item => {
                    e.push(item.dateTime);
                    f.push(item.num)
                });
                this.xBar=e;
                this.yBar1=f;
                let g=[];
                balanceLineCharts.forEach(item => {
                    g.push(item.num)
                });
                this.yBar2=g;
                let h=[];
                let i=[];
                activeUserLineCharts.forEach(item => {
                    h.push(item.dateTime);
                    i.push(item.num)
                });
                this.xBars=h;
                this.yBars1=i;
                let j=[];
                purchaseLineCharts.forEach(item => {
                    j.push(item.num)
                });
                this.yBars2=j;
                let k=0
                this.yBar1.map(item=>{k=k+parseFloat(item)})
                this.chong=k
                let l=0
                this.yBar2.map(item=>{l=l+parseFloat(item)})
                this.xiao=l
                let m=0
                this.yBars1.map(item=>{m=m+parseFloat(item)})
                this.huo=m
                let n=0
                this.yBars2.map(item=>{n=n+parseFloat(item)})
                this.fu=n

                this.drawPie()
                this.drawLine()
                this.drawBar()
                this.drawBars()
            }
        })
        },
        clickTime(index){
             this.activeIndex=index;
        },
        drawPie(){
            const chartDom = document.getElementById('myChart');
            const myChart = echarts.init(chartDom);
            let option
            option = {
                backgroundColor:'#fff',
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                legend: {
                    icon: 'circle',
                    show: true,
                    orient: 'vertical',
                    left: '60%', //设置图例左右的位置
                    x: '80%', //x : 左（left）、右（right）、居中（center）
                    y: 'center', //y : 上（top）、下（bottom）、居中（center）
                    textStyle: { //图例文字的样式
                        width:'10px',
                        marginLength:'10px'
                    },
                    itemWidth: 34,
                    formatter: function(name) {
                        let data = option.series[0].data;
                        let total = 0;
                        let tarValue = 0;
                        let target;
                        for (let i = 0, l = data.length; i < l; i++) {
                            total += data[i].value;
                            if (data[i].name == name) {
                                tarValue = data[i].value;
                                target = data[i].value;
                            }
                        }
                        let p = (tarValue / total * 100).toFixed(2);
                        return   name + '  ' + ' '+ ' '+target.toFixed(2) +' ' +' '+ ' ' + p + '%';
                    }, 
                },
            series: [
                {
                center: ['30%', '50%'],  //设置饼图的左右上下位置 
                name: '',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                itemStyle:{
                    borderWidth:1, //设置border的宽度有多大
                    borderColor:'#fff',
                },
                emphasis: {
                    label: {
                    show: true,
                    fontSize: '14',
                    formatter:'{b}\r\n {c}'
                    }
                },
                labelLine: {
                    show: false
                },
                data: this.huanData
                }
            ]
            };
            option && myChart.setOption(option);
        },
        drawLine(){
            let option = {
            title: {
                text: null // 图标题
            },
            tooltip: {
                trigger: 'axis' //提示框组件，坐标轴触发
            },
            legend: {//图例组件，
            x:'center',
            y:'bottom',
            bottom:"20",
            data: ['订单数', '进账金额', '客流量', ],    
    
            },
            grid: { //直角坐标系内绘图网格
                left: '5%', //grid 组件离容器左侧的距离
                bottom: '20%',
            },
            xAxis: {  // 如果有多个同类组件，那么就是个数组。例如这里有三个 X 轴。
                type: 'category',
                boundaryGap: false, //坐标轴两边留白策略 默认为 true，这时候刻度只是作为分隔线，标签和数据点都会在两个刻度之间的带(band)中间
                data: this.xAxisData, // x轴数据
                showMinLabel: true,//显示最小值
                showMaxLabel: true,//显示最大值
            },
            yAxis: {
                type: 'value',
                splitLine :{ //网格线
                    lineStyle:{
                    type:'dashed' //设置网格线类型 dotted：虚线 solid:实线
                }
             }
            },
            series: [ // 这里有多个系列，也是构成一个数组。[表示实际数据]
                {
                    name: '订单数',
                    type: 'line',
                    smooth: true,
                    data: this.yAxisData1, // y轴数据1
                    color:'rgba(253, 179, 16, 1)',
                    symbol: 'circle',//设置拐点为实心圆
                    symbolSize: 5, // 设置折线上圆点大小
                },
                {
                    name: '进账金额',
                    type: 'line',
                    smooth: true,
                    data: this.yAxisData2, // y轴数据2
                    color:'rgba(56, 191, 128, 1)',
                    symbol: 'circle',
                    symbolSize: 5, // 设置折线上圆点大小
                },
                {
                    name: '客流量',
                    type: 'line',
                    smooth: true,
                    data: this.yAxisData3, // y轴数据3
                    color:'rgba(252, 78, 74, 1)',
                    symbol: 'circle',
                    symbolSize: 5, // 设置折线上圆点大小
                },
          ]
        }
            this.myChartOne = echarts.init(document.getElementById('main')) // 创建 echarts 实例。
            this.myChartOne.setOption(option)      // 调用 setOption 将 option 输入 echarts，然后 echarts 渲染图表。
        },
        drawBar(){
            let option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            legend: {
                x:'center',
                y:'bottom',
                bottom:"40%",
                data: [ '会员充值金额','会员消费金额',]
            },
            grid: { //直角坐标系内绘图网格
                left: '5%', //grid 组件离容器左侧的距离
                bottom: '20%',
            },
            dataZoom: [
                {
                    type: 'inside', // 内置在坐标系中
                    startValue: 0, // 数据窗口范围的起始数值。如果设置了 dataZoom-inside.start 则 startValue 失效。
                    endValue: 10, // 数据窗口范围的结束数值。如果设置了 dataZoom-inside.end 则 endValue 失效。
                    zoomLock: true, // 是否锁定选择区域（或叫做数据窗口）的大小。
                }
            ],
            xAxis: [
                {
                type: 'category',
                data: this.xBar,
                axisPointer: {
                    type: 'shadow'
                }
                }
            ],
            yAxis: [
                {
                type: 'value',
                name: '',
                min: 0,
                // max: 250,
                // interval: 100,
                splitLine :{ //网格线
                    lineStyle:{
                    type:'dashed' //设置网格线类型 dotted：虚线 solid:实线
                }
                }
                },
            ],
            series: [
                {
                    name: '会员充值金额',
                    type: 'bar',
                    tooltip: {
                        valueFormatter: function (value) {
                        return value + ' 元';
                        }
                    },
                    barWidth : 30,
                    color:'rgba(60, 144, 247, 1)',
                    data:this.yBar1
                },
                {
                    name: '会员消费金额',
                    type: 'bar',
                    tooltip: {
                        valueFormatter: function (value) {
                        return value + ' 元';
                        }
                    },
                    barWidth : 30,
                    color:'#55bfc0',
                    data:this.yBar2
                },
            ]
            };
            this.myChartOne = echarts.init(document.getElementById('bar'))
            this.myChartOne.setOption(option)          
        },
        drawBars(){
            let option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            legend: {
                x:'center',
                y:'bottom',
                bottom:"40%",
                data: ['活跃会员数', '复购数',]
            },
            grid: { //直角坐标系内绘图网格
                left: '5%', //grid 组件离容器左侧的距离
                bottom: '20%',//图例与图形之间的距离
            },
            xAxis: [
                {
                type: 'category',
                data: this.xBars,
                axisPointer: {
                    type: 'shadow'
                }
                }
            ],
            dataZoom: [
                {
                    type: 'inside', // 内置在坐标系中
                    startValue: 0, // 数据窗口范围的起始数值。如果设置了 dataZoom-inside.start 则 startValue 失效。
                    endValue: 10, // 数据窗口范围的结束数值。如果设置了 dataZoom-inside.end 则 endValue 失效。
                    zoomLock: true, // 是否锁定选择区域（或叫做数据窗口）的大小。
                }
            ],
            yAxis: [
                {
                type: 'value',
                name: '',
                min: 0,
                // max: 250,
                // interval: 50,
                splitLine :{ //网格线
                    lineStyle:{
                    type:'dashed' //设置网格线类型 dotted：虚线 solid:实线
                    }
                }
                },
            ],
            series: [
                {
                    name: '活跃会员数',
                    type: 'bar',
                    tooltip: {
                        valueFormatter: function (value) {
                        return value + ' ';
                        }
                    },
                    barWidth : 30,
                    color:'#339fff',
                    data:this.yBars1,
                },
                {
                    name: '复购数',
                    type: 'bar',
                    tooltip: {
                        valueFormatter: function (value) {
                        return value + ' ';
                        }
                    },
                    color:'rgba(255, 87, 51, 1)',
                    barWidth : 30,
                    data:this.yBars2
                },
            ]
            };
            this.myChartOne = echarts.init(document.getElementById('bars'))
            this.myChartOne.setOption(option)          
        },


    }
}
</script>
<style lang="less" scoped>
.orderCount{
    background: #f8f8ff;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    h3{
        padding: 15px 15px 0 15px;
        font-weight: 700;
        color: #666;
        height: 5%;
        box-sizing: border-box;
    }
    .searchForm{
        width: 100%;
        padding: 10px;
        display: flex;
        .btn , .btn:active, .btn:focus, .btn:hover{
            background: #2a82e4;
            border-color: #2a82e4;
            margin-left: 20vw;
        }
    }
    .firstModal{
        background: #ffffff;
        padding:1vh 0;
        ul{
            display: flex;
            flex-wrap: wrap;
            width:100%;
            li{
                width: 25%;
                display: flex;
                padding:2vh 4vh;
                .img{
                    height: 45px;
                    width: 45px;
                    background: rgba(255, 170, 113, 0.44);
                    position: relative;
                    border-radius:40px;
                    margin-right:1vw;
                    /deep/.anticon svg{
                        position: absolute;
                        top:0;left: 0;
                        right:0;
                        bottom: 0;
                        margin: auto;
                        font-size: 25px;
                        color: rgba(240, 100, 28, 1);
                    }
                }
                .font{
                    font-size: 1.8vh;
                }
                .count{
                    font-size: 2.5vh;
                }
            }
        }
    }
    .secondModal{
        margin-top:2vh;
        background: #ffffff;
        display: flex;
        .leftModal{
            display: flex;
            flex-wrap: wrap;
            // width:40%;
            flex: 1;
            border-right: 1px solid rgba(234, 234, 234, 1);
            padding:3vh  0 3vh 3vh;
            .circleprogress{
                margin:0 4vh;
                margin-bottom:3vh;
                width:14vh;
                height: 14vh;
                border:5px solid #fdb310;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 1.8vh;
            }
            .circleprogress:nth-child(2){
                border:5px solid #38bf80;
            }
            .circleprogress:nth-child(3){
                border:5px solid #fc4e4a;
            }
             .circleprogress:nth-child(4){
                border:5px solid #4abffc;
            }
        }
        .rightModal{
            // margin-left: -7vw;;
            
        }

    }
    .thirdModal{
        margin-top:2.5vh;
        background: white;
        height:289px;
        width:100%;
    }
    .fourthModal{
        margin-top:2.5vh;
        background: white;
        width:100%;
        .top{
            padding-top:1vh;
        }
        .bartitle{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2.0vh;
            line-height: 3vh;
            span{
                display: inline-block;
                width:10vw;
                text-align: center;
            }
            .normal{
                font-size: 1.8vh;
            }
            .left{
                color:#2a82e4;
            }
            .right{
                color:#55bfc0;
            }
        }
    }
    .fifthModal{
        margin-top:2.5vh;
        background: white;
        height:315px;
        width:100%;
    }
    .top{
            padding-top:1vh;
        }
     .bartitle{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2.0vh;
            line-height: 3vh;
            span{
                display: inline-block;
                width:10vw;
                text-align: center;
            }
            .normal{
                font-size: 1.8vh;
            }
            .left{
                color:#2a82e4;
            }
            .right{
                color:#55bfc0;
            }
        }
}
::v-deep .ant-input{
    height: 36px;
    width:23vw;
}
::v-deep .ant-select-selection--single{
    height: 36px;
}
::v-deep .ant-select-selection__rendered{
    line-height: 34px;
}
</style>